import React from 'react'
import Link from 'gatsby-link'
import { graphql } from "gatsby"
import * as utils from '../../utils'
import Layout from "../../components/layout"

export const query = graphql`
  query ProductsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allProducts: allMarkdownRemark(filter: { frontmatter: {type: { eq: "product" }}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            nid
            title
            images
            price
            date
            teaser
            promotion
          }
        }
      }
    }
  }
`

export default (props) => {
  // console.log('props', props)
  const { data } = props
  var sortedproducts = data.allProducts.edges
    .map(t => t.node)
    .map(t => ({...t, promo: t.frontmatter.promotion || 0, timestamp: Date.parse(t.frontmatter.date)}))
    .sort((a, b) => b.timestamp - a.timestamp)
    .sort((a, b) => b.promo - a.promo);

  // console.log('products', sortedproducts)

  return <Layout>
    <div className="contentheader">
      <h1>Store</h1>
      <p>Här hittar du exklusiva produkter från On-Axis artister. </p>
      <p>Alla priser är inkl. frakt (inom Sverige). För att beställa, skicka ett mail till <a href="mailot:order@on-axis.com">order@on-axis.com</a> med namnet på de produkter du vill köpa.</p>
      <p>Shopen drivs ideellt och orderbekräftelse kommer vanligtvis inom 48h, en order fullföljs och skickas i mån av tid, men normalt inom en vecka, betalning sker via Swish.</p>
      <hr />
    </div>
    <div className="products">
      <ul>
        {
          sortedproducts.map(product => {
            return <li>
              <Link to={'/' + product.fields.slug} className="cover">
                <img src={utils.getImageURL(product.frontmatter.images[0], 500)} alt={product.frontmatter.title} className="hero" />
                <div className="text">
                  <b>{product.frontmatter.title}</b>
                  &nbsp;
                  <i>{product.frontmatter.price}kr</i><br/>

                  {product.frontmatter.teaser}
                </div>
              </Link>
            </li>
          })
        }
      </ul>
    </div>
  </Layout>;
}
